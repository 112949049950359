import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import InformationLayout from "../../layouts/InformationLayout.jsx";
import indexData from "./index.json";
import Downloads from "../../components/_ui/Downloads.jsx";
export const _frontmatter = {
  "title": "Pergola Planning - Sustainable Forestry – Timber Policy Statement"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children,
  pagesList
}) => <InformationLayout pagesList={indexData.pageIndex}>
    {children}
  </InformationLayout>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Sustainable Forestry – Timber Policy Statement`}</h1>
    <h2>{`Timber policy`}</h2>
    <p>{`Perfect Pergolas Ltd, in recognition of our responsibility to the environment, our customers and suppliers, bases our commercial activities on legal and well-managed forests.`}</p>
    <p>{`As such, we are committed to procuring timber and timber products from independently verified sources. Perfect Pergolas Ltd recognises that the independent, third party certification of forests and the supply chain is the optimal way of gaining assurances that the timber we trade originates from legal and well-managed forests.`}</p>
    <p>{`We source our raw materials from certified suppliers where possible. We actively encourage and support all our suppliers to go through the certification process and to increase the volume of certified material traded.`}</p>
    <p>{`Where certification is unavailable and alternative sources or products are sought, then as a minimum standard we will not procure any timber that is prohibited under Appendix 1 of the Convention on International Trade in Endangered Species (CITES) and that it is neither directly or indirectly involved in the following activities:`}</p>
    <ol>
      <li parentName="ol">{`Illegal logging or the trade in illegal wood or forestry products`}</li>
      <li parentName="ol">{`Violation of traditional and human rights in forestry operations`}</li>
      <li parentName="ol">{`Destruction of high conservation values in forestry operations`}</li>
      <li parentName="ol">{`Significant conversion of forests to plantations or non-forest use`}</li>
      <li parentName="ol">{`Introduction of genetically modified organisms in forestry operations`}</li>
      <li parentName="ol">{`Violation of any of the ILO Core Conventions, as defined in the ILO Declaration on Fundamental Principles and Rights at Work 1998`}</li>
    </ol>
    <p>{`To ensure continuous improvement, the owner is responsible for the implementation and maintenance of this policy and will ensure that environmental issues relevant to this policy are discussed regularly at the highest level of management.`}</p>
    <p>{`We will ensure that all employees associated with Timber Purchasing are encouraged to act in accordance with this policy and are given appropriate education and training to do so. This policy will be reviewed annually to consider progress made by our supply chain, changes in industry practice and the requirements of our customers.`}</p>
    <p>{`Signed for and on behalf of Perfect Pergolas Ltd:`}</p>
    <p>{`Adrian Valentine
Managing Director`}</p>
    <p><em parentName="p">{`Date: 14th September 2012`}</em></p>
    <p><em parentName="p">{`Perfect Pergolas Ltd, PO Box 283, Cirencester GL7 9EY`}</em></p>
    <p><em parentName="p">{`Tel: 0844 800 4703 Email: `}<a parentName="em" {...{
          "href": "mailto:info@perfectpergolas.co.uk"
        }}>{`info@perfectpergolas.co.uk`}</a></em></p>
    <p><em parentName="p">{`Company Registration No: 7246705`}</em></p>
    <h2>{`Downloads & Support`}</h2>
    <Downloads files={[{
      filename: "Pergola assembly guide",
      path: "/downloads/ASSEMBLY.pdf"
    }, {
      filename: "Pergola wood treatment information",
      path: "/downloads/EN-Wolmanit_CX_enduser_022010.pdf"
    }]} mdxType="Downloads" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      